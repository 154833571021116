import { Link, useSearchParams } from "react-router-dom";
import React from "react";
import { useFetchProjectById } from "./fetchProjects";
const BlogListItem = (props) => {

const {item} = props
console.log("item", item)
  return (
    <div className="blog-post-item">
      <div className="blog-post-thumb">
        <Link to={item.url}>
          <img src={item.img} alt="" />
        </Link>
      </div>
      <div className="blog-post-content">
        <div className="blog-meta">
          <ul>
            <li>
              <Link to={item.url}>
                <i className="far fa-user"></i>
                {item.author}
              </Link>
            </li>
            <li>
              <i className="far fa-clock"></i>
              {new Date(item.dateTime).toDateString()} 
            </li>
            
          </ul>
        </div>
        <h2 className="title">
          <Link to={item.url}>{item.title}</Link>
        </h2>
        <p>{item.shortText}</p>
        <Link to={`/blog-details?id=${item.id}`} className="btn btn-two">
          Read more <i className="fas fa-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default BlogListItem;
