import React, { useState } from "react";
import emailjs from '@emailjs/browser';




const ContactOneForm = () => {
  const [formdata, setFormdata] = useState({})

const handleSubmit = (e) => {

  console.log('templateParams', formdata)
  
  emailjs.send('service_recnlp2','template_mark3x1', formdata, 'VyrcDwF_iZV1IMG1U')
	.then((response) => {
	   console.log('SUCCESS!', response.status, response.text);
	}, (err) => {
	   console.log('FAILED...', err);
	});
}



  return (
    <div className="contact-form-wrap">
      <form  >
        <div className="row">
          <div className="col-md-6">
            <div className="form-grp">
              <input type="text" placeholder="Enter your Name" required onChange={e => setFormdata({...formdata, from_name: e.target.value})}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-grp">
              <input type="email" placeholder="Enter you email" required onChange={e => setFormdata({...formdata, email: e.target.value})}/>
            </div>
          </div>
        </div>
        <div className="form-grp">
          <textarea name="notes" placeholder="Enter your message" onChange={e => setFormdata({...formdata, notes: e.target.value})}></textarea>
        </div>
        <div className="submit-btn text-center">
          <button onClick={handleSubmit} className="btn">
            Send Massage
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactOneForm;
