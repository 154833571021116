import React from "react";
import img01 from "../../assets/img/images/treydorex.png";

import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
            
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  The World’s <span>Premiere</span> Platform That Incorporates AI In A Growing Economy
                </h2>
              </div>
              <p>
                Metaverse company utilizing AI to grow it's vast structure in gaming, storylines, metaverse economics with development in education.
              </p>
              <Link to="/" className="btn">
                Purchase Tokens Soon
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
