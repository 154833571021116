import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Mid of Q1 2022",
      title: "Concept",
      info: [
        "Research proof of concept idea",
        "Consult various sources",
        "Information gathering stage",
      ],
    },
    {
      roadmapTitle: "Q2 2022",
      title: "Research",
      info: [
        "Forming a team to build idea",
        "Formulate active plan",
        "Set milestone goals to achieve",
      ],
    },

    {
      roadmapTitle: "Mid of Q3 2022",
      title: "Development",
      info: [
        "Continued team building",
        "Ongoing plan adaptation",
        "Assess and reassess road map",
      ],
    },

    {
      roadmapTitle: "Q4 2022",
      title: "Development",
      info: [
        "Onboarding new employees with training",
        "Bringing artists up to speed to implement designs",
        "Adaptation of ongoing plan",
      ],
    },
    {
      roadmapTitle: "Mid of Q1 2023",
      title: "Development",
      info: [
        "Whitepaper and prototype coin",
        "World building ongoing",
        "Story lore ongoing",
      ],
    },
    {
      roadmapTitle: "Q2 2023",
      title: "Development",
      info: [
        "Finalizing NFT comic book and story content",
        "Creation of Comic book hero NFT",
        "Social media marketing",
      ],
    },
     {
      roadmapTitle: "Q3 2023",
      title: "Development",
      info: [
        "Website and newsletter",
        "Blog, social media, PR release",
        "Monetize business ICO, NFT",
      ],
    },
    {
      roadmapTitle: "Mid of Q4 2023",
      title: "Operational",
      info: [
        "World building unreal engine",
        "Continued marketing",
        "Ongoing research",
      ],
    },
    {
      roadmapTitle: "Mid of Q1 2024",
      title: "Operational",
      info: [
        "World building unreal engine",
        "Continued marketing",
        "Ongoing research",
      ],
    },
    {
      roadmapTitle: "Mid of Q2 2024",
      title: "Operational",
      info: [
        "World building unreal engine",
        "Continued marketing",
        "Ongoing research",
      ],
    },
    {
      roadmapTitle: "Mid of Q3 2024",
      title: "Operational",
      info: [
        "World building unreal engine",
        "Marketing pre city launch",
        "Increased community awareness",
      ],
    },
    {
      roadmapTitle: "Mid of Q4 2024",
      title: "Operational",
      info: [
        "World building unreal engine",
        "First VR city launch",
      ],
    },

  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
                Treydora Strategy and <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              //ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
