import React from "react";
import Countdown from "react-countdown";
import useCountdown from "./useCountdown";

// let formatter = new Intl.DateTimeFormat('en-US', {
//   timeZone: 'America/New_York',
//   year: 2023,
//   month: 'dec',
//   day: 22,
//   hour: 0,
//   minute: 8,
//   second: 0
//  });
 
//  let futureDateETC = new Date(formatter.format(new Date()))

const getSavedTime = () => {
  let savedTime = localStorage.getItem("savedTime")
  if (savedTime) return savedTime
  savedTime = new Date('2023-12-22').getTime() - Date.now()
  localStorage.setItem("savedTime", savedTime)
  return savedTime
}
const CountDownOne = () => {
  // const counter = useCountdown({
  //   duration: new Date('2023-12-22').getTime() - new Date().getTime(),
  //   callbacks: {

  //   }
  // })
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
        {/* {JSON.stringify(counter, null, 2)} */}
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>hour
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>minute
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>second
          </div>
        </>
      );
    }
  };

  return (
    <div className="coming-time">
      <Countdown date={new Date('2023-12-22 20:00:00 EST') } renderer={renderer} precision={1000}/>
    </div>
  );
};

export default CountDownOne;
