import cn from "classnames";
const Button = (props) => {
    const standaloneOnramp = window?.StripeOnramp?.Standalone({
        source_currency: 'usd',
        amount: { source_amount: '100' },
      });
      const redirectUrl = standaloneOnramp.getUrl();
    return (
        <div className="flex justify-center w-full">
        <a href={redirectUrl} className={cn("btn", )} target="_blank">FUND WALLET (via Credit Card)</a>
        </div>
    )
}

export default Button