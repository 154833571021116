import React from "react";
import { Link } from "react-router-dom";

const BlogRecentItem = (props) => {
  console.log('blogrecentitem', props.item)
  return (
    <div className="rc-post-item">
      <div className="rc-post-thumb">
        <Link to={props.item.url}>
         <img src={props.item.img} alt="" />
        
        </Link>
      </div>

      <div className="rc-post-content">
        <span>{props.item.time}</span>

        <h4 className="title">
          <Link to={`/blog-details?id=${props.item.id}`}>{props.item.title}</Link>
        </h4>
      </div>
    </div>
  );
};

export default BlogRecentItem;
