import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/react-odometer-theme.css";
import "./assets/css/default.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/tailwind.css"
import "bootstrap/dist/js/bootstrap.bundle.min";
import { CLIENT_ID_CODE } from "const/addresses"; 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThirdwebProvider activeChain="ethereum" clientId={process.env.REACT_APP_CLIENT_ID_CODE}>
    <BrowserRouter>
     
          <App />
      
    </BrowserRouter>  
    </ThirdwebProvider>
);
