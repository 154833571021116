import React, {useState} from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo2.png";
import { scrollToTop } from "../../lib/helpers";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const FooterOne = () => {

  const notify = () => toast.success('You are now signed up for our newsletter!', {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });

  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "members": [
        {
          "email_address": email,
          "email_type": "text",
          "status": "subscribed"
        }
      ]
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://us-central1-treydora-3056f.cloudfunctions.net/mailchimpHandler", requestOptions)
      .then(response => response.json())
      .then(result => {
        setEmail('');
      })
      .catch(error => console.log('error', error));
      };

  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="BigTech Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                     Another way to stay up to date follow us on our social media links!
                    </p>
                    <ul className="footer-social relative">
                    <li>
                        <a href="https://www.linkedin.com/in/treydora-productions-inc-1055b5233/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    <li>
                        <a href="https://www.reddit.com/user/treydoraproductions">
                          <i className="fab fa-reddit"></i>
                        </a>
                      </li>
                    <li>
                        <a href="https://www.pinterest.com/treydoraproductionsinc/_saved/">
                          <i className="fab fa-pinterest"></i>
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://t.me/World_Of_Treydora">
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/TreydoraP_inc">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/Treydora-Productions-Inc-102535382383944">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/treydoraproductions/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://discord.gg/D7BJQSqAAE">
                          <i className="fab fa-discord"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="fab fa-discord"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  {/* <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/">How it Works</Link>
                      </li>
                      <li>
                        <Link to="/">Create</Link>
                      </li>
                      <li>
                        <Link to="/">Explore</Link>
                      </li>
                      <li>
                        <Link to="/">Terms & Services</Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Community</h4>
                  {/* <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Help Center</Link>
                      </li>
                      <li>
                        <Link to="/">Partners</Link>
                      </li>
                      <li>
                        <Link to="/">Suggestions</Link>
                      </li>
                      <li>
                        <Link to="/">Blog</Link>
                      </li>
                      <li>
                        <Link to="/">Newsletters</Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Subscribe Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Stay up to date and sign up to our newsletter for all the latest news first!
                    </p>
                    <form action="#" onSubmit={handleSubmit} >
                      <input
                        type="email"
                        placeholder="Info@gmail.com"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                      <button onClick={notify} type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023. All Rights Reserved Treydora Productions, Inc.</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                {/* <div className="footer-menu"> */}
                {/*   <ul> */}
                {/*     <li> */}
                {/*       <Link to="#">Terms and conditions</Link> */}
                {/*     </li> */}
                {/*     <li> */}
                {/*       <Link to="#">Privacy policy</Link> */}
                {/*     </li> */}
                {/*     <li> */}
                {/*       <Link to="#">Login / Signup</Link> */}
                {/*     </li> */}
                {/*   </ul> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
