import React from "react";
import img01 from "../../assets/img/team/team1.png";
import img02 from "../../assets/img/team/team2.png";
import img03 from "../../assets/img/team/team3.png";
import img04 from "../../assets/img/team/team4.png";
import img05 from "../../assets/img/team/team5.png";
import img06 from "../../assets/img/team/team6.png";
import img07 from "../../assets/img/team/team7.png";
import img08 from "../../assets/img/team/team8.png";
import img09 from "../../assets/img/team/team9.png";
import img10 from "../../assets/img/team/team10.png";
import img11 from "../../assets/img/team/team11.png";
import img12 from "../../assets/img/team/team12.png";

import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "Mark Loudermilk",
      designation: "Founder & CO",
    },
    {
      src: img02,
      name: "Varun Kumar",
      designation: "Head of Marketing",
    },
    {
      src: img03,
      name: "Sujeet Singh SDLC CORP",
      designation: "Social Media Marketing",
    },
    {
      src: img04,
      name: "Harry Emmanuel",
      designation: "Product Manager",
    },
    {
      src: img05,
      name: "Thiago de Bittencourt Buss",
      designation: "Lead writer",
    },
    {
      src: img06,
      name: "Altisam Ali",
      designation: "Writer",
    },
    {
      src: img07,
      name: "Sergey Smirnov",
      designation: "Special Projects Division",
    },
    {
      src: img08,
      name: "Nikolaos Lefas",
      designation: "Digital Artist",
    },
    {
      src: img09,
      name: "Jacob Carson",
      designation: "Digital Artist",
    },
    {
      src: img10,
      name: "Ronald Acosta",
      designation: "Comic Book Artist",
    },
    {
      src: img11,
      name: "Nghia Pham",
      designation: "Comic Book Artist",
    },
    {
      src: img12,
      name: "Mahmoud Swielam",
      designation: "Comic Book Artist",
    },
  ];

  return (
    <section className="team-area pt-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">OUr team</span>
              <h2 className="title">
                The Leadership <br /> <span>Team</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
