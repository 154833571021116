import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import image from '../../assets/images/items/3.gif';
import image1 from '../../assets/images/avatar/1.jpg';
import image3 from '../../assets/images/items/2.gif';
import image4 from '../../assets/images/items/1.jpg';
import image5 from '../../assets/images/items/2.jpg';
import image6 from '../../assets/images/items/1.gif';
import { data } from '../../data/data';
import  {IoMdClose,BsExclamationOctagon } from "../../assets/icons/vander";
import LayoutOne from 'layouts/LayoutOne';
// import { MediaRenderer, Web3Button, useActiveClaimConditionForWallet, useAddress, useContract, useContractMetadata, useTotalCirculatingSupply } from "@thirdweb-dev/react";
// import { CONTRACT_ADDRESS } from "../../const/addresses";

export default function ItemDetail() {

    const params = useParams();
    const id = params.id
    const creater = data.find((creatorr) => creatorr.id === parseInt (id));

    const [activeIndex, setIndex] = useState(0);
    const [placeBid , setPlaceBid] = useState(false)
    const [ buyNow, setBuyNow] =  useState(false)

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);

    // const {
    //     contract
    // } = useContract(CONTRACT_ADDRESS);

    // const {
    //     data: ContractMetadata,
    //     isLoading: isContractMetadataLoading,
    // } = useContractMetadata(contract);

    // const {
    //     data: activeClaimPhase,
    //     isLoading: isActiveClaimPhaseLoading,
    // } = useActiveClaimConditionForWallet(contract, address);

    // const {
    //     data: totalSupply,
    //     isLoading: isTotalSupplyLoading,
    // } = useTotalCount(contract);

    // const {
    //     data: totalClaimed,
    //     isLoading: isTotalClaimedLoading,
    // } = useTotalCirculatingSupply(contract);

    // const maxClaimable = parseInt(activeClaimPhase?.maxClaimablePerWallet || "0");



    return (
        <>
            {/* <Navbar /> */}
            <LayoutOne>
            <section className="relative pt-28 md:pb-24 pb-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5">
                            <img src={creater?.image ? creater?.image : image} className="rounded-md shadow dark:shadow-gray-700" alt="" />

                            <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                                <div>
                                    <span className="font-medium text-slate-400 block mb-1">Contract Address</span>
                                    <Link to="#" className="font-medium text-violet-600 underline block">#</Link>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Token ID</span>
                                    <span className="font-medium block">#</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Blockchain</span>
                                    <span className="font-medium block">ETH</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Deposit & Withdraw</span>
                                    {/* <span className="font-medium block">Unsupported</span> */}
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 lg:ms-8">
                            <h5 className="md:text-2xl text-xl font-semibold">{creater?.title ? creater?.title :"Probably A Label #3277"}</h5>

                            <span className="font-medium text-slate-400 block mt-2">From this collection: <Link to="/creator-profile" className="text-violet-600">{creater?.subtext ? creater?.subtext : "@FunnyGuy"}</Link></span>

{/*                             
                            <iframe
                                src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x5556728Babb7561b01b516E92174a10b2DC840C4&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fethereum.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fethereum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=cac3279db218b6c66b4910dd3fe822cc&theme=dark&primaryColor=purple"
                                width="600px"
                                height="600px"
                                style={{maxWidth:'100%'}}
                                frameBorder="0"
                            ></iframe> */}
    <div className="w-full bg-gray-900 h-full">
        <h2 className="text-md font-semibold">Coming Soon</h2>

    </div>


                            {/* <div>
                                {!isContractMetadataLoading && (
                                    <><div>
                                        <MediaRenderer
                                            src={contractMetadata?.image} />
                                    </div><div>
                                        <h1>{contractMetadata?.name}</h1>
                                        <p>{contractMetadata?.description}</p>
                                        {isActiveClaimPhaseLoading ? (
                                            <div>
                                                <p>Claim Phase: {activeClaimPhase?.metadata?.name}</p>
                                                <p>Price: {ethers.utils.formatUnits(activeClaimPhase?.price!</p>
                                                </div>
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                        {!isTotalSupplyLoading && !isTotalClaimedLoading ? (
                                                <p>Claimed: {totalClaimed?.toNumber()} / {totalSupply?.toNumber()}</p>
                                        ) : (
                                           <p>Loading....</p> 
                                        )};
                                        {address ? (
                                            <Web3Button
                                            contractAddress={CONTRACT_ADDRESS}>Claim NFT</Web3Button>
                                        ) : (
                                            <p>Connect your wallet to claim</p>
                                        )}
                                        
                                        </div></></>
                            </div>
                                )} */}



                           
                        </div>
                    </div>
                </div>
                <div className={`fixed z-50 overflow-hidden inset-0 m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden" }`}>
                <div className="relative w-full h-auto max-w-md p-4">
                    <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                            <h5 className="text-xl font-semibold">Place a Bid</h5>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" >
                                <IoMdClose onClick={()=> setPlaceBid(!placeBid)}/>
                            </button>
                        </div>
                        <div className="p-6">
                            <form className="text-start">
                                <div className="grid grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-semibold" >Your Bid Price:</label>
                                        <input name="etherium" id="number" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="00.00 ETH" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-semibold" >Enter Your QTY:</label>
                                        <input name="quantity" id="number2" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="0"/>
                                        <span className="text-slate-400 text-sm"><span className="text-slate-900 dark:text-white mt-1">Note:</span> Max. Qty 5</span>
                                    </div>
                                </div>
                            </form>

                            <div className="pt-4 border-t dark:border-t-gray-800">
                                <div className="flex justify-between">
                                    <p className="font-semibold text-sm"> You must bid at least:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.22 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Service free:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 0.05 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Total bid amount:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.27 ETH </p>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" data-modal-toggle="NftBid" className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white w-full"><i className="mdi mdi-gavel"></i> Place a Bid</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div  className={`fixed z-50 overflow-hidden inset-0 m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${buyNow ? "" :"hidden"}`}>
                <div className="relative w-full h-auto max-w-md p-4">
                    <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                            <h5 className="text-xl font-semibold">Checkout</h5>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" >
                                <IoMdClose  onClick={() => setBuyNow(!buyNow)}/>
                            </button>
                        </div>
                        <div className="p-6">
                            <form className="text-start">
                                <div className="grid grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-semibold" >Your Price:</label>
                                        <input name="etherium" id="number3" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="00.00 ETH" />
                                    </div>
                                </div>
                            </form>

                            <div className="pt-4 border-t dark:border-t-gray-800">
                                <div className="flex justify-between">
                                    <p className="font-semibold text-sm"> You must bid at least:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.22 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Service free:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 0.05 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Total bid amount:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.27 ETH </p>
                                </div>
                            </div>

                            <div className="flex items-center p-4 bg-red-600/10 text-red-600 mt-4 rounded-lg">
                                <BsExclamationOctagon className="text-3xl"/>

                                <div className="ms-2">
                                    <span className="block font-semibold">This creator is not verified</span>
                                    <span className="block">Purchase this item at your own risk</span>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white w-full"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            {/* <Footer />
            <Switcher /> */}
            </LayoutOne>
        </>
    )
}
