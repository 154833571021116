import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect, useState } from "react";
import WOW from "wow.js";
//import HomeTwo from "./pages/Home/HomeTwo";
import Blog from "./pages/Blog/Blog";
import ExplorePage from "./pages/Explore";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import ItemDetail from "./pages/ItemDetail";
import  ItemDetailUnreleased from "./pages/ItemDetailUnreleased"
import ScrollToHashElement from "hashScroll";
import Profile from "./pages/Profile/Profile";
import {loadStripeOnramp} from '@stripe/crypto';
import {CryptoElements, OnrampElement} from './StripeElements';
import { useAddress } from "@thirdweb-dev/react";

const stripeOnrampPromise = loadStripeOnramp("pk_test_51NDGRuBnfQO4DTiOqe26WAhAitYniq0lmz5M5idrngv6QLuzG9qydE8J8K8QjpnwJmLIqRTOI01BHfIatMIhGv0600yrR90jn8"
);
const apiUrl = `https://api.stripe.com/v1/crypto/onramp_sessions`

const stripeKey = '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' //process?.env?.STRIPE_KEY ?? ''
function App() {
  // const address = useAddress()
  // const [session, setSession] = useState()
  // useEffect(() =>{
  //   fetch(apiUrl, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type':'application/x-www-form-urlencoded',
  //       'Authorization': `Bearer ${stripeKey}`
  //     },
  //     body: new URLSearchParams({
  //         destination_networks: ['ethereum'],
  //       // wallet_address:  address,
  //       // transaction_details: {

  //       //   destination_network: 'ethereum',
  //       //   destination_currency: 'eth'
  //       // }
  //     }).toString()
  //   })
  //   .then(res => res.json())
  //   .then(data => {
  //     console.log('Created session:', data)
  //     setSession(data)
  //   })
  //   .catch(err => console.log(err))
  // }, [])
  
  
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    <ScrollToHashElement />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-two" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetailsPage />} />
        <Route path="/explore" element={<ExplorePage />} />
        <Route path='/item-detail' element={<ItemDetail />} />
        <Route path='/item-detail/:id' element={<ItemDetail />} />
        <Route path='/item-detail-unreleased/:id' element={<ItemDetailUnreleased />} />
        <Route path='/profile/:address' element={<Profile />} />
      </Routes>
    </>
  );
}

export default App;
