import React from "react";
import blogImg from "../../assets/img/blog/blog_img01.jpg";
import blogDetails01 from "../../assets/img/blog/blog_details01.jpg";
import blogDetails02 from "../../assets/img/blog/blog_details02.jpg";
import blogDetails03 from "../../assets/img/blog/blog_details03.jpg";
import { Link } from "react-router-dom";

const generateParagraphsText = text => {
  return text?.split("\n").map((item, i) => {
    return <p key={i}>{item}</p>;
  });
}
const BlogDetails = ({ data }) => {
  console.log("data", data)
  return (
    <div className="blog-post-item blog-details-wrap">
      <div className="blog-post-thumb">
        <img src={data?.fields?.image?.fields?.file?.url} alt="" />
      </div>
      <div className="blog-post-content">
        <div className="blog-meta">
          <ul>
            <li>
              <a href="/blog-details">
                <i className="far fa-user"></i>{data?.fields?.author}
              </a>
            </li>
            <li>
              <i className="far fa-clock"></i>{data?.fields?.dateTime}
            </li>
          </ul>
        </div>
        <h2 className="title">
          {data?.fields?.title}
        </h2>
        <div>
          {data?.fields?.longText}
        </div>


        <div className="bd-approach-wrap">
          <div className="row align-items-center">
            <div className="col-46">
              <div className="bd-approach-content">
                <h4 className="title">{data?.fields?.heading1}</h4>
                <p>
                  {data?.fields?.heading1Text}
                </p>

                {/* <ul> */}
                {/*   <li> */}
                {/*     <i className="fas fa-check"></i>Extramural Funding */}
                {/*   </li> */}
                {/*   <li> */}
                {/*     <i className="fas fa-check"></i>Bacteria Markers */}
                {/*   </li> */}
                {/*   <li> */}
                {/*     <i className="fas fa-check"></i>Nam nec mi euismod euismod */}
                {/*   </li> */}
                {/* </ul> */}
              </div>
            </div>

            <div className="col-54">
              <div className="bd-approach-img">
                <img src={data?.fields?.detailsImg?.fields?.file?.url} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="bd-technology-content ">
          <h2 className="title">{data?.fields?.heading2}</h2>
          <p className="display-linebreak"> 
            {generateParagraphsText(data?.fields?.heading2Text)}
          </p>
        </div>

        <div className="blog-details-img">
          <div className="row">
            <div className="col-md-6">
              <img src={data?.fields?.lowerPic1?.fields?.file?.url} alt="" />
            </div>
            <div className="col-md-6">
              <img src={data?.fields?.lowerPic2?.fields?.file?.url} alt="" />
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default BlogDetails;
