import React from "react";
import BlogRecentItem from "./BlogRecentItem";
import { useFetchProjects } from '../BlogList/fetchProjects';

const BlogRecents = () => {
  const {loading, projects} = useFetchProjects();
  const recentItems = projects.sort((a, b) =>  new Date(b.dateTime) - new Date(a.dateTime))

  return (
    <div className="blog-widget">
      <h2 className="bw-title">Recent Post</h2>
      <div className="rc-post-wrap">
        {recentItems.map((item, index) => (
          <BlogRecentItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default BlogRecents;
