import { useState, useEffect } from 'react';
import { createClient } from "contentful";

const client = createClient({
	space: 'h3cj3sfjcsro',
	environment: 'master',
	accessToken: '8UO8wjtB8m42rTrvNglbZjJefoRNXxzrLBw5crf3jTc',
});


export const useFetchProjects = () =>{
	console.log('triggered')
	const [loading, setLoading] = useState(true);
	const [projects, setProjects]= useState([]);
	const getData = async() =>{
		try {
			const response = await client.getEntries({content_type:'projects'});
			
			const projects = response.items.map((item)=>{
				console.log('contains url', item)
				const {title, url, image, author, dateTime, shortText} = item.fields;
				const id = item.sys.id;
				const img = image?.fields?.file?.url;
				return {title,url,id,img, author, dateTime, shortText };
			})
			setProjects(projects);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}
	useEffect(()=> {
	getData();
	},[]);
	return {loading, projects};
}

export const useFetchProjectById = (id) => {
	console.log('triggered')
	const [loading, setLoading] = useState(true);
	const [project, setProject]= useState([]);
	const getData = async() =>{
		try {
			const response = await client.getEntry(id);
			console.log('response', response)
			
			
			setProject(response);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}
	useEffect(()=> {
	getData();
	},[id]);
	return {loading, project};
}