import React from "react";
import BlogListItem from "./BlogListItem";
import img01 from "../../assets/img/blog/blog_img01.jpg";
import img02 from "../../assets/img/blog/blog_img02.jpg";
import img03 from "../../assets/img/blog/blog_img03.jpg";
import { useFetchProjects } from './fetchProjects';



const BlogList = () => {
  console.log(
    'blog list'
    )
  const { loading, projects } = useFetchProjects();

console.log("projects", projects)
  if(loading) return <>Loading....</>

  return (
    <>
      {projects.map((blog, index) => (
        <BlogListItem key={blog.id} item={blog} />
      ))}
    </>
  );
};

export default BlogList;
