import React, {useEffect, useState} from "react";

import { Link, useSearchParams } from "react-router-dom";

import BlogCommentForm from "../../components/BlogDetails/BlogCommentForm";
import BlogComments from "../../components/BlogDetails/BlogComments";
import BlogDetails from "../../components/BlogDetails/BlogDetails";
import LayoutBlog from "../../layouts/LayoutBlog";

import {useFetchProjectById} from "../../components/BlogList/fetchProjects"
const BlogDetailsPage = () => {

  const [blog, setBlog] = useState(null)


  const [searchParams] = useSearchParams();

  const id = searchParams.get("id")
  console.log('searchParams', id)
  const { loading, project } = useFetchProjectById(id);
  console.log('project by id', id, project)
  return (
    <LayoutBlog pageTitle={"Blog Details"} item={"BLOG DETAILS"}>
      {project ? <BlogDetails data={project}/>: null} 
    {/*   <BlogComments /> */}
    {/*   <BlogCommentForm /> */}
    </LayoutBlog>
  );
};

export default BlogDetailsPage;
